<template>
  <div class="IdAuthentication document-content">身认证</div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.IdAuthentication {
}
</style>